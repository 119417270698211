import { useEffect, useRef, useState } from "react";
import { Tooltip as ReactTooltip } from "react-tooltip";
import {
    PREVIEW_COMMENTS_KEY,
    PREVIEW_DESCRIPTIONS_KEY,
    PREVIEW_THAMES,
    PREVIEW_URL,
    TYPE_PAGE_OPTIONS,
} from "../../constants/pwa";
import Loader from "../../components/UI/Loader";
import LightModeIcon from "../../components/icons/LightModeIcon";
import IphoneSwitch from "../../components/UI/IphoneSwitch";

const PreviewPWA = ({ data, activeLang, scrollToElm }) => {
    const [previewData, setPreviewData] = useState(null);
    const [isHiddenPreview, setIsHiddenPreview] = useState(false);
    const [theme, setTheme] = useState(localStorage.getItem("pwa_preview_theme") || PREVIEW_THAMES.light);

    const containerRef = useRef(null);

    const hidePreview = () => {
        const PWAPage = document.getElementById("create-edit-pwa");
        setPreviewData(null);
        setIsHiddenPreview(true);
        PWAPage.classList.remove("with-preview");
    };

    useEffect(() => {
        const createPreviewData = () => {
            if (!data || !activeLang) {
                hidePreview();
                return;
            }
            setIsHiddenPreview(false);
            const PWAPage = document.getElementById("create-edit-pwa");
            PWAPage.classList.add("with-preview");
            const descriptions = {};
            const comments = [];
            let lang = "";

            if (data.descriptions[activeLang]) {
                lang = activeLang;
            } else if (data.defaultLang) {
                lang = data.defaultLang;
            } else {
                lang = Object.keys(data.descriptions)[0];
            }

            Object.keys(data).forEach((key) => {
                if (key === "comments") {
                    const commentsData =
                        data.comments[activeLang] ||
                        data.comments[data.defaultLang] ||
                        Object.values(data.comments)[0] ||
                        {};
                    Object.values(commentsData).forEach((comment) => {
                        comments.push(comment);
                    });
                } else if (key === "descriptions") {
                    const desc = data.descriptions[lang];

                    Object.keys(desc).forEach((fieldName) => {
                        const value = desc[fieldName];
                        descriptions[fieldName] = value;
                    });
                } else {
                    descriptions[key] = data[key];
                }
                delete descriptions.comments;
            });
            localStorage.setItem(PREVIEW_DESCRIPTIONS_KEY, JSON.stringify(descriptions));
            localStorage.setItem(PREVIEW_COMMENTS_KEY, JSON.stringify(comments));
            setPreviewData({
                lang,
                defaultLang: data.defaultLang || activeLang,
                seed: Date.now(),
                scrollToElm,
            });
        };

        const timeoutId = setTimeout(createPreviewData, 1000);

        return () => {
            clearTimeout(timeoutId);
        };
    }, [data, activeLang, scrollToElm]);

    const changeTheme = (fieldname, val) => {
        const newTheme = val ? PREVIEW_THAMES.light : PREVIEW_THAMES.dark;
        setTheme(newTheme);
        localStorage.setItem("pwa_preview_theme", newTheme);
    };

    if (isHiddenPreview) {
        return null;
    }

    return (
        <div className="pwa-preview-wrapper" ref={containerRef}>
            <div className="pwa-preview-container">
                <div className="pwa-preview">
                    {data.typePage === TYPE_PAGE_OPTIONS.APP.value && (
                        <div className="theme-block">
                            <label
                                htmlFor="preview-theme"
                                data-tooltip-content="Тема сменяется в зависимости от настроек смартфона пользователя"
                                data-tooltip-id="theme-hint"
                            >
                                <LightModeIcon />
                            </label>
                            <IphoneSwitch
                                id="preview-theme"
                                isChecked={theme === PREVIEW_THAMES.light}
                                onChange={changeTheme}
                            />
                            <ReactTooltip id={`theme-hint`} type="light" effect="solid" />
                        </div>
                    )}
                    <div className="android-img"></div>
                    {previewData ? (
                        <iframe
                            src={`${PREVIEW_URL}?${Object.keys(previewData)
                                .map((key) => `${key}=${previewData[key]}`)
                                .join("&")}${theme ? "&theme=" + theme : ""}`}
                        />
                    ) : (
                        <div className="iframe-loader">
                            <Loader />
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

export default PreviewPWA;
