import Select from "react-select";

const SelectGroup = ({ initialGroups, selectedGroups, updateOptions }) => (
    <Select
        className="select small select-grouping"
        options={initialGroups}
        getOptionValue={(e) => e.field}
        placeholder="Группировка"
        isSearchable={false}
        value={selectedGroups.map((field) => initialGroups.find((opt) => opt.field === field)).filter(Boolean)}
        onChange={(newValues) => updateOptions({ selectedGroups: newValues.map(({ field }) => field) })}
        isMulti
    />
);

export default SelectGroup;
