const ArrowUpIcon = () => (
    <svg
        fill="#000000"
        height="200px"
        width="200px"
        version="1.1"
        id="Layer_1"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 512 512"
    >
        <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
        <g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round"></g>
        <g id="SVGRepo_iconCarrier">
            <g transform="translate(-1)">
                <g>
                    <g>
                        <path d="M402.067,179.2l-128-170.667c-8.533-11.378-25.6-11.378-34.133,0l-128,170.667 c-10.548,14.064-0.513,34.133,17.067,34.133h64v85.333c0,11.782,9.551,21.333,21.333,21.333h85.333 c11.782,0,21.333-9.551,21.333-21.333v-85.333h64C402.58,213.333,412.614,193.264,402.067,179.2z M299.667,170.667 c-11.782,0-21.333,9.551-21.333,21.333v85.333h-42.667V192c0-11.782-9.551-21.333-21.333-21.333h-42.667L257,56.889 l85.333,113.778H299.667z"></path>
                        <path d="M299.667,341.333h-85.333c-11.782,0-21.333,9.551-21.333,21.333S202.551,384,214.333,384h85.333 c11.782,0,21.333-9.551,21.333-21.333S311.449,341.333,299.667,341.333z"></path>
                        <path d="M299.667,405.333h-85.333c-11.782,0-21.333,9.551-21.333,21.333S202.551,448,214.333,448h85.333 c11.782,0,21.333-9.551,21.333-21.333S311.449,405.333,299.667,405.333z"></path>
                    </g>
                </g>
            </g>
        </g>
    </svg>
);

export default ArrowUpIcon;
