import React from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import LoginPage from "./LoginPage";
import useScript from '../hooks/useScript';
import useStyle from '../hooks/useStyle';

const style = `
*, :before, :after {
    margin: 0;
    padding: 0;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
}

body {
    font: 400 1.4rem/1.8rem 'Inter', sans-serif;
    color: #fff;
    background: #140c0a;
}

body.show {
    overflow: hidden;
}

body.show .wrap {
    height: 100vh;
}
`

const StartPage = () => {
    useStyle('/css/swiper-bundle.min.css')
    useScript('/js/jquery-3.6.0.min.js');
    useScript('/js/swiper-bundle.min.js');
    useScript('/js/sp.js');

    return (
        <HelmetProvider>
            
        <div id="start-page">
            <Helmet>
                <style>{style}</style>
            </Helmet>
            <div className="wrap">
                <div className="preview">
                    <img className='bg-preview' src="/sp_img/newDesign/hero-bg.webp"/>
                    <header className="header">
                        <div className="container d-flex">
                            <a href="/" className="logo">
                                <img src="/sp_img/newDesign/logo.webp" alt="" className="logo__image" />
                                {/* <span className="logo__text">Deeplink Agency</span> */}
                                
                            </a>
                            <nav className="nav_1" id="nav">
                                <ul className="nav-list">
                                    {/*<li className="nav-list__item"><a href="#" className="nav-list__link">Услуги</a></li>*/}
                                    <li className="nav-list__item"><a href="https://t.me/da_apps_support" className="nav-list__link" target="_blank" rel="noopener noreferrer">Контакты</a></li>
                                    <li className="nav-list__item dropdown">
                                        <a id="cabinet-menu" href="#" className="nav-list__link nav-list__link_btn" data-bs-toggle="dropdown">Личный Кабинет</a>
                                        <div id="cabinet-menu-container" className="dropdown-menu noclose">
                                            <LoginPage/>
                                        </div>
                                    </li>
                                </ul>
                                <a href="#" className="close-menu"></a>
                            </nav>
                            <a href="#" className="btn-menu">
                                <span className="btn-menu__line"></span>
                                <span className="btn-menu__line"></span>
                                <span className="btn-menu__line"></span>
                            </a>
                        </div>
                    </header>
                    {/* <section className='hero-block'> */}
                    <div className="container">
                        <div className="preview__text">
                            <h1 className="title_size-xl preview__title">DA <span className="text_primary">APPS</span></h1>
                            <p>Автоматизированная платформа для работы 
                            с мобильными приложениями</p>
                            <div className="preview__btn">
                                <a href="https://t.me/da_apps_support"  target="_blank" rel="noopener noreferrer" className="btn">
                                    Связаться с нами
                                    <span className="icon-arrow anim-items anim__showR"></span>
                                </a>
                            </div>
                        </div>
                        <div className="preview__image">
                            <picture>
                                <source srcSet='/sp_img/newDesign/hero.webp' ></source>
                                <source srcSet='/sp_img/newDesign/hero-min.webp' ></source>
                                <img src="" alt="" className="img-fluid" />
                            </picture>
                        </div>

                    </div>
                    {/* </section> */}

                </div>
               <div className='start-page-line'/>
                <div className="advantages">
                    <div className="container">
                        <div className="d-flex">
                        <div className="advantages__image anim-items anim__showL"><img src="/sp_img/newDesign/advantages.webp" alt="" className="img-fluid" /></div>
                            <div className="advantages__text anim-items anim__showR">
                                <div className="advantages__headeing">
                                    <div className="title_size-sm">
                                        УНИКАЛЬНЫЙ ПЕРЕНОС АУДИТОРИИ </div>
                                    <p className="text_dark">Мы используем собранную базу самых качественных игроков для переноса событий из прошлых приложений</p>
                                </div>
                                <div className="advantages-item d-flex align-items-center">
                                    <div className="advantages-item__icon"><img src="/sp_img/newDesign/advantage-icon-1.webp" alt="" className="img-fluid" /></div>
                                    <div className="advantages-item__text">
                                        <div className="advantages-item__title">Повышенный траст</div>
                                        <p>Лёгкость запуска с Facebook</p>
                                    </div>
                                </div>
                                <div className="advantages-item d-flex align-items-center">
                                    <div className="advantages-item__icon"><img src="/sp_img/newDesign/advantage-icon-2.webp" alt="" className="img-fluid" /></div>
                                    <div className="advantages-item__text">
                                        <div className="advantages-item__title">Качество игроков</div>
                                        <p>Улучшение показателей окупаемости трафика (по данным рекламодателя)</p>
                                    </div>
                                </div>
                                <div className="advantages-item d-flex align-items-center">
                                    <div className="advantages-item__icon"><img src="/sp_img/newDesign/advantage-icon-3.webp" alt="" className="img-fluid" /></div>
                                    <div className="advantages-item__text">
                                        <div className="advantages-item__title">Скорость</div>
                                        <p>Моментальная возможность залива приложения с оптимизацией за Регу/Деп</p>
                                    </div>
                                </div>
                                <div className="advantages-item d-flex align-items-center">
                                    <div className="advantages-item__icon"><img src="/sp_img/newDesign/advantage-icon-4.webp" alt="" className="img-fluid" /></div>
                                    <div className="advantages-item__text">
                                        <div className="advantages-item__title">Самый высокий показатель CR</div>
                                        <p>ТОП показатели из клик2инстал, инстал2рег, рег2деп</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='start-page-line'/>
                <div className="about">
                    {/* <div className="about__image anim-items anim__showOpacity">
                        <img src="/sp_img/about.jpg" alt="" className="img-fluid" />
                        <img src="/sp_img/about-img-1.png" alt="" className="img-fluid about__img-1" />
                        <img src="/sp_img/about-img-2.png" alt="" className="img-fluid about__img-2" />
                        <img src="/sp_img/about-img-3.png" alt="" className="img-fluid about__img-3" />
                    </div> */}
                    <div className="container d-flex">
                        <div className="about__text anim-items anim__showL">
                            <div className="about__heading">
                                <div className="title_size-sm">SMART PUSH <br/> в приложениях</div>
                            </div>
                            <div className="about-item">
                                <div className="about-item__image about-item__image_blue"><img src="/sp_img/newDesign/about-icon-1.webp" alt="" className="img-fluid" /></div>
                                <div className="about-item__text">Нативные пуши под все ГЕО</div>
                            </div>
                            <div className="about-item">
                                <div className="about-item__image about-item__image_yellow"><img src="/sp_img/newDesign/about-icon-2.webp" alt="" className="img-fluid" /></div>
                                <div className="about-item__text">Автопуши по событиям инст/не рег, рег/не деп, деп/не повторный деп</div>
                            </div>
                            <div className="about-item">
                                <div className="about-item__image about-item__image_violet"><img src="/sp_img/newDesign/about-icon-3.webp" alt="" className="img-fluid" /></div>
                                <div className="about-item__text">Оповещения 3 раза в день</div>
                            </div>
                            <div className="about-item">
                                <div className="about-item__image about-item__image_white"><img src="/sp_img/newDesign/about-icon-4.webp" alt="" className="img-fluid" /></div>
                                <div className="about-item__text">Мотивирующие картинки и иконки, цепляющий текст</div>
                            </div>
                        </div>
                        <img className='about-img anim-items anim__showOpacity' src='/sp_img/newDesign/about-img.webp' loading='lazy' />
                    </div>
                </div>
                <div className='start-page-line'/>
                <div className="design anim-items anim__showOpacity">
                    <div className="container">
                        <div className="title_size-md text_center text_upper design__title anim-items anim__show">СОЧНЫЙ, АГРЕССИВНЫЙ ДИЗАЙН</div>
                        <div className="text_center anim-items anim__show"><h4>Дизайн, который цепляет, конвертит и удерживает. 🚀💰</h4>
                            <p>Дизайн приложений в Play Market подбирается для максимального конверта и быстрой модерации. <br/> Категории: аркада, казино... 3+, 12+, 16+, 18+</p></div>
                        {/* <div className="design__image anim-items anim__showOpacity">
                            <img src="/sp_img/design.png" alt="" className="img-fluid" />
                        </div> */}
                    </div>
                    <img className='design-img anim-items anim__showOpacity' alt='games' loading='lazy' src='/sp_img/newDesign/design-img.webp'/>
                </div>
                <div className="work anim-items anim__showOpacity">
                    <div className="container">
                        <div className="title_size-md text_center text_upper anim-items anim__show">УДОБНЫЙ ДАШБОРД</div>
                        <div className='work-desc'>
                            <h4>Полный контроль и прозрачность в одном окне.</h4>
                            <p>Интуитивный интерфейс, мгновенная аналитика и гибкие настройки — всё, что нужно для эффективного управления трафиком и оптимизации конверсий. Следи за ключевыми метриками в реальном времени, принимай решения быстрее и выжимай максимум из каждого клика.</p>
                        </div>
                        <div className="work__row">
                            <div className="work-item anim-items anim__show"><img src="/sp_img/newDesign/work-1.webp" alt="table" loading='lazy' className="img-fluid" /></div>
                            <div className="work-item anim-items anim__show"><img src="/sp_img/newDesign/work-2.webp" alt="table" loading='lazy' className="img-fluid" /></div>
                            <div className="work-item anim-items anim__show"><img src="/sp_img/newDesign/work-3.webp" alt="table" loading='lazy' className="img-fluid" /></div>
                        </div>
                    </div>
                </div>
                <div className='start-page-line'/>
                <div className="partners">
                    <div className="container">
                        <div className="title_size-md text_center anim-items anim__show">Наши партнеры</div>
                        <div className='partner-content'>
                            <button type='button' className='slider-prev'>
                                <svg width="42" height="42" viewBox="0 0 42 42" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M27.1211 8.74805L14.8711 20.998L27.1211 33.248" stroke="#A9A9A9" strokeWidth="3.9375" strokeLinecap="round" strokeLinejoin="round"/></svg>
                            </button>
                        
                            <div className='swiper partners-swiper'>
                                <div className='swiper-wrapper'>
                                <a className="swiper-slide"><img src="/sp_img/partner/affhub_white.png" alt="Affhub" className="img-fluid" /></a>
                                <a className="swiper-slide"> <img src="/sp_img/partner/adspect-white.webp" alt="Adspect" className="img-fluid" /></a>
                                <a className="swiper-slide"> <img src="/sp_img/partner/logo_push_express.png" alt="Push Express" className="img-fluid" /></a>
                                </div>
                            </div>
                            <button type='button' className='slider-next'>
                            <svg width="42" height="42" viewBox="0 0 42 42" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M27.1211 8.74805L14.8711 20.998L27.1211 33.248" stroke="#A9A9A9" strokeWidth="3.9375" strokeLinecap="round" strokeLinejoin="round"/></svg>
                            </button>
                        </div>
                    </div>
                </div>
                <div className='start-page-line'/>
                <div className="container" style={{display: 'none'}}>
                    <footer className="footer d-flex">
                        <a href="/" className="logo footer__logo">
                            <span className="logo__text">DA <span className="text_primary">APPS</span></span>
                        </a>
                        <ul className="footer-nav d-flex">
                            {/*<li className="footer-nav__item"><a href="#" className="footer-nav__link">Бонусы</a></li>
                            <li className="footer-nav__item"><a href="#" className="footer-nav__link">Акции</a></li>*/}
                        </ul>
                        <div className="social-list d-flex align-items-center">
                            {/*<a href="#" className="social-list__link"><img src="/sp_img/youtube.png" alt="" /></a>*/}
                            <a href="https://t.me/da_apps_bot" target="_blank" rel="noopener noreferrer" className="social-list__link"><img src="/sp_img/bot.png" alt="" /></a>
                            <a href="https://t.me/da_apps_support" target="_blank" rel="noopener noreferrer" className="social-list__link"><img src="/sp_img/telegram.png" alt="" /></a>
                        </div>
                    </footer>
                </div>
                <footer>
                    <img className='footer-bg' src='/sp_img/newDesign/footer-bg.webp'/>
                    <div className='container'>
                        <div className='footer-content'>
                            <img src='/sp_img/newDesign/footer-gnom.webp' className='gnom' />
                            <div className='left-block'>
                                <h2 className="title_size-xl preview__title">DA <span className="text_primary">APPS</span></h2>
                                <p>максимум эффективности,<br/> минимум усилий.</p>
                                <div className='socials'>
                                    <a href="https://www.youtube.com/playlist?list=PLniccqvspbXR_lE6fl3XB7DEB1s6Z8X2L" target="_blank" rel="noopener noreferrer" className="social-list__link youtube-link"><img src="/sp_img/newDesign/youtube-icon.png" alt="youtube" /></a>
                                    <a href="https://t.me/da_apps_support" target="_blank" rel="noopener noreferrer" className="social-list__link"><img src="/sp_img/telegram.png" alt="telegram" /></a>
                                </div>
                            </div>
                            {/* <div className='right-block'>
                                <a href='/'>Privacy Policy</a>
                            </div> */}
                            
                        </div>
                        <img className='footer-gold-img' loading='lazy' alt='gold' src='/sp_img/newDesign/footer-gold.webp'/>
                    </div>
                </footer>
                <div className='start-page-line last-line'/>
            </div>
        </div>
        </HelmetProvider>
    );
}

export default StartPage;