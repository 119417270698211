import Select from "react-select";
import { useMemo } from "react";
import { DATE_OPTIONS } from "../../constants/report";
import CustomDatePicker from "./CustomDatePicker";
import CloseIcon from "../../components/icons/CloseIcon";

const DataRange = ({ dateRangeValue, updateOptions, timezones, timezone, timeStart, timeEnd }) => {
    const handlerDateRangeChange = ({ value }) => {
        updateOptions({ dateRangeValue: value });
    };

    const handlerTimezoneChange = ({ timezone }) => {
        updateOptions({ timezone });
    };

    const dateRangeOptions = useMemo(() => Object.values(DATE_OPTIONS).map((value) => ({ value, label: value })), []);

    return (
        <>
            <Select
                className="select small timezone-select"
                value={timezones.find((opt) => opt.timezone === timezone)}
                onChange={handlerTimezoneChange}
                getOptionValue={(e) => e.timezone}
                options={timezones}
            />
            {dateRangeValue === DATE_OPTIONS.TIME_RANGE || dateRangeValue === DATE_OPTIONS.RANGE_OF_DAYS ? (
                <div className="custom-dates">
                    <CustomDatePicker
                        onValidDateTime={(time) => updateOptions({ time_start: time })}
                        timeMode="start"
                        selectedDate={timeStart}
                        enableManualTime={dateRangeValue === DATE_OPTIONS.TIME_RANGE}
                    />
                    <div className="line" />
                    <CustomDatePicker
                        onValidDateTime={(time) => updateOptions({ time_end: time })}
                        timeMode="end"
                        selectedDate={timeEnd}
                        enableManualTime={dateRangeValue === DATE_OPTIONS.TIME_RANGE}
                    />
                    <button
                        className="close-btn"
                        type="button"
                        onClick={() => handlerDateRangeChange({ value: DATE_OPTIONS.TODAY })}
                    >
                        <CloseIcon />
                    </button>
                </div>
            ) : (
                <Select
                    className="select small"
                    value={dateRangeOptions?.find(({ value }) => value === dateRangeValue) || ""}
                    onChange={handlerDateRangeChange}
                    isSearchable={false}
                    options={dateRangeOptions}
                />
            )}
        </>
    );
};

export default DataRange;
