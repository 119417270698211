import "./iphoneSwitch.css";

const IphoneSwitch = ({ isChecked, onChange, fieldName, id = "" }) => (
    <label className="iphone-switch">
        <input 
            id={id} 
            type="checkbox"
            checked={isChecked}
            onChange={(e) => onChange(fieldName, e.target.checked)}
        />
        <span className="knob" />
    </label>
);

export default IphoneSwitch;
