import BasePage from "../base/BasePage";
import {RestrictedArea} from "../../wrappers/RestrictedArea";
import {UserGroup} from "../../context/User";
import Button from "../../components/UI/Button";
import {StoreID} from "../../common/commonTypes";
import {formatDateTime, pick} from "../../common/commonUtil";
import React from "react";
import axios from "axios";
import {getResponseErrorsList} from "../../util/util";

const PushEventState = {
    NEW: 0,
    SENT: 1,
    FAILED: 2,
    IRRELEVANT: 3,
    RETRY: 10,
    RETRY_2: 11,
    APPLIED: 101,
    toString(pushEventState) {
        switch (pushEventState) {
            case PushEventState.NEW: return "⚪ New";
            case PushEventState.SENT: return "🟢 Sent";
            case PushEventState.FAILED: return "⛔ Failed";
            case PushEventState.IRRELEVANT: return "⛔ Irrelevant";
            case PushEventState.RETRY: return "🔁 Retrying";
            case PushEventState.RETRY_2: return "🔁 Retrying..";
            case PushEventState.APPLIED: return "Applied";
            default: return "Unknown";
        }
    }
};

const EventApi = {
    PUSH_DEVICE: 1,
    FB_API: 2,
    APPSFL_API: 3,
    PUSHEXPRESS_API: 4,
    FB_PIXEL_API: 5,
    toString(eventApi) {
        switch (eventApi) {
            case EventApi.PUSH_DEVICE: return "PushDev";
            case EventApi.FB_API: return "FB App";
            case EventApi.APPSFL_API: return "Appsflyer";
            case EventApi.PUSHEXPRESS_API: return "PushExpress";
            case EventApi.FB_PIXEL_API: return "FBPixel";
            default: return "Unknown";
        }
    }
};

class AdminPushEventsLogs extends BasePage {
    constructor(props){
        super(props);
        this.state = {
            loading: true,
            executing: false
        }
    }

    componentDidMount() {
    }

    async onSubmit(event) {
        event.preventDefault();

        if (!this.busy) {
            try {
                this.busy = true;
                this.setState({executing: true});
                let res = await axios.post('/getpusheventslogs', {...pick(this.state,
                        ['domain','sub_key_1','sub_value_1','sub_key_2','sub_value_2','device_id']) });
                this.setState({executing: false, logs: res.data.logs, errors: {}});
            } catch (err) {
                this.setState({executing: false, logs: [], errors: getResponseErrorsList(err)});
            }
            this.busy = false;
        }
    }

    renderPage() {
        const { executing, logs, device_id } = this.state;

        const inputField = (fieldName,description,tip,wide=false,disabled=false) => (
            [
                <label htmlFor={fieldName} style={{margin: '8px'}}>{description + (description ? ':' : '')}</label>,
                <input id={fieldName} name={fieldName} type="text" onChange={this.onFieldChanged.bind(this,fieldName)}
                       value={this.state[fieldName]} placeholder={tip} style={{width: wide ? '360px' : '120px',
                    opacity: disabled ? 0.3 : 1.0}} disabled={disabled}/>
            ]
        );

        let lastDeviceID;

        const _formatDateTime = dateTimeStr => dateTimeStr.replace("T", " ").split(".")[0].slice(0, -3);

        const _formatImage = url => (<a href={url} target='_blank'><img src={url} style={{height: '24px', width: '24px'}}></img></a>);

        return (
            <RestrictedArea allowedTo={UserGroup.DEVELOPER}>
                <div className="page">
                    <h1>Логи пушей</h1>
                    <form className='left-align' onSubmit={this.onSubmit.bind(this)} style={{maxWidth: 'none'}}>
                        <ul>
                            <li className="field">
                                {inputField("domain","Домен","",true, !!device_id)}
                            </li>
                            <li className="field" style={{marginLeft: '60px'}}>
                                {inputField("sub_key_1","", "Саб", false, !!device_id)}
                                {inputField("sub_value_1","", "Значение", false, !!device_id)}
                            </li>
                            <li className="field" style={{marginLeft: '60px'}}>
                                {inputField("sub_key_2","", "Саб", false, !!device_id)}
                                {inputField("sub_value_2","", "Значение", false, !!device_id)}
                            </li>
                            <li className="field">
                                {inputField("device_id","Device ID","",true)}
                                {this.renderError("device_id")}
                            </li>

                            <li className="submit">
                                <div>
                                    <div>
                                        <Button
                                            type='submit'
                                            withoutRounded
                                            title='Показать логи'
                                            isLoading={executing}
                                        />
                                    </div>
                                    {this.renderRestErrors()}
                                    {this.state.execMsg &&
                                        <div className="tinyTip holder">{this.state.execMsg}</div>
                                    }
                                </div>
                            </li>
                        </ul>
                    </form>

                    <div style={{marginTop: '30px'}}>
                        <div className='table-responsive perfect-scrollbar'>
                            <table className="table" style={{minWidth: 700}}>
                                <thead>
                                <tr>
                                    <th>Устройство</th>
                                    <th>Приложение</th>
                                    <th>Время</th>
                                    <th>Событие</th>
                                    <th>Метод</th>
                                    <th>Статус</th>
                                </tr>
                                </thead>
                                <tbody>
                                {logs && logs.map((r,i) => {
                                    const deviceIDChanged = lastDeviceID !== r.device_id;
                                    lastDeviceID = r.device_id;
                                    return <tr key={i} style={{ marginTop: deviceIDChanged ? '10px' : '2px'}}>
                                        <td style={{fontSize: '10px'}}>{r.device_id}</td>
                                        <td>#{r.app_id} ({StoreID.toString(r.store_id)})</td>
                                        <td>{formatDateTime(r.time)}</td>
                                        <td>{r.event}</td>
                                        <td>{EventApi.toString(r.method)} [Pr#{r.processor_id}] {r.fake ? 'FAKE' : ''}</td>
                                        <td>{PushEventState.toString(r.state)}</td>
                                    </tr>
                                })}
                                {logs && logs.length >= 100 &&
                                    <tr><td style={{fontSize: '16px'}}>[ .......конкретизируйте сабы....... ]</td></tr>
                                }
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>

            </RestrictedArea>
        );
    }

}

export default AdminPushEventsLogs;