import React, { Component } from 'react';
import axios from "axios";
import {getResponseErrorsList, getTimeDifferenceDesc} from "../../util/util";
import Link from "../../widgets/Link";
import { Tooltip as ReactTooltip } from "react-tooltip";
import AudioPlayer from "../../widgets/AudioPlayer";
import './push.css';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { registerLocale } from  "react-datepicker";
import ru from 'date-fns/locale/ru';
/*import 'emoji-mart/css/emoji-mart.css';*/
import {useLocation, useNavigate} from "react-router-dom";
import Button from '../../components/UI/Button';
import Textarea from '../../components/UI/Textarea';
import EmojiPicker from './EmojiPicker';

registerLocale('ru', ru);

const {PUSH_SOUNDS} = require('../../common/commonTypes');


class CreateNewPush extends Component {

    constructor(props) {
        super(props);
        this.state = {
            name: '',
            loading: true,
            errors: {},
            selectedSoundID: 'default',
            timeStart: new Date(),
            showEmojiPicker: null
            /*pushSupport: null*/
        };
    }

    componentDidMount() {
        let inputData = this.props.location.state;
        if (inputData.templatePush) {
            let {message, title, name, deeplinks_created_by} = inputData.templatePush;
            if (name !== "" && !name.endsWith(' (repeat)')) {
                name += ' (repeat)';
            }
            this.setState({message, title, name});
            this.fetchData(deeplinks_created_by.split(','));
        } else {
            this.fetchData(inputData.deeplinks);
        }
    }

    async fetchData(deeplinksIDs) {
        this.setState({loading: true});

        try {
            let res = await axios.post('/push_prepare', {deeplinks_ids: deeplinksIDs});
            this.setState({loading: false, deeplinks: res.data.deeplinks});
        } catch(e) { this.setState({errors: getResponseErrorsList(e)}); }
    }

    ///////////////////////////////

    onSubmit(event) {
        event.preventDefault();

        if (!this.state.deeplinks) {
            this.setState({errors: {common: 'Error'}});
            return;
        }

        if (this.busy) {
            console.log('busy');
            return;
        }

        this.busy = true;
        axios.post('/newpush', {
            deeplinks_ids: this.state.deeplinks.map(d => d.id),
            timeStart: this.state.timeStart,
            name: this.state.name,
            title: this.state.title,
            message: this.state.message,
            sound: this.state.selectedSoundID,
            /*pushSupport: this.state.pushSupport*/
        }).then((res) => {
            this.props.navigate('/push');
            this.busy = false;
        }).catch((err) => {
            this.setState({errors: getResponseErrorsList(err)});
            this.busy = false;
        });
    }

    getError(param) {
        if (this.state.errors && param in this.state.errors) {
            return this.state.errors[param];
        }
        return '';
    }

    onFieldChanged(param) {
        return (e) => this.setState({ errors: '', [param]: e.target.value });
    }

    onSoundChanged(e) {
        this.setState({selectedSoundID: e.target.value});
    }

    onTimeStartChanged = date => {
        this.setState({timeStart: date});
    };

    showEmojiPicker(emojiPicker) {
        this.setState({showEmojiPicker: (this.state.showEmojiPicker !== emojiPicker) ? emojiPicker : ''})
    }

    onEmojiSelect(field,emoji) {
        this.setState({[field]: (this.state[field] ? this.state[field] : '') + emoji.native});
        /*let sym = emoji.unified.split('-');
        let codesArray = [];
        sym.forEach(el => codesArray.push('0x' + el));
        let emojiChar = String.fromCodePoint(...codesArray);
        this.setState({ [field]: (this.state[field] ? this.state[field] : '') + emojiChar });*/
    }

    render() {
        const renderError = (fieldName) => (
            <div className="error holder"><div className="message">{this.getError(fieldName)}</div></div>
        );

        let {deeplinks,selectedSoundID,showEmojiPicker} = this.state;

        let totalReadyDeviceCount = 0;
        let totalDeviceCount = 0;
        let _this = this;

        // const renderEmojiPicker = (field) => (
        //     <div className="widget floating" style={{textAlign: 'center', position: 'relative'}}>
        //         <Picker style={{ position: 'absolute', zIndex: '1', top: '-5px', right: '0px' }} title='Подберите эмодзи…'
        //                 i18n={{ search: 'Поиск', categories: { search: 'Результаты поиска', recent: 'Часто используемые' } }}
        //                 set="google" emojiSize={20} perLine={8} sheetSize={32}
        //                 onSelect={_this.onEmojiSelect.bind(_this,field)}
        //         />
        //     </div>
        // );

        const timeSendDesc = () => {
            let timeDiff = (this.state.timeStart - new Date());
            if (timeDiff < 60000) return `Отправляем сейчас`;
            return `Отправляем через ${getTimeDifferenceDesc(timeDiff)}`;
        };

        return (
            <div className="page newPush">
                <h1>Запустить пуш</h1>
                <div className='center-section' style={{maxWidth: 600}}>
                    <div className='card'>
                        <form className="wide" onSubmit={this.onSubmit.bind(this)} noValidate>
                            {renderError("deeplinks")}
                            <ul>
                                <li className="field">
                                    <div><label htmlFor="name">Название пуша</label></div>
                                    <div className="tip">Необязательно. Для вашего удобства, чтобы следить за исполнением</div>
                                    <div><input id="name" name="name" type="text" onChange={this.onFieldChanged("name")} value={this.state.name} required/></div>
                                    {renderError("name")}
                                </li>

                                { deeplinks &&
                                <li className="field">
                                    <div>На устройства с диплинков:</div>
                                    <div>
                                    <div className='readOnlyCode'>
                                        {deeplinks.map(deeplink=>{
                                            let readyDeviceCount = deeplink.readyForPushDeviceCount ? parseInt(deeplink.readyForPushDeviceCount) : 0;
                                            let deviceCount = parseInt(deeplink.deviceCount);
                                            totalReadyDeviceCount += readyDeviceCount;
                                            totalDeviceCount += deviceCount;
                                            let deeplinkName = deeplink.name === '' ? `#${deeplink.id}` : `${deeplink.name} (#${deeplink.id})`;
                                            return <div className="tinyItem" key={deeplink.id} data-tip={`${readyDeviceCount} уствойств готово к принятию пушей из ${deviceCount} инслаллов`} data-for={'tooltipDeeplink'+deeplink.id}>
                                                {deeplinkName} ({readyDeviceCount}/{deviceCount})
                                                <ReactTooltip id={'tooltipDeeplink'+deeplink.id} delayShow={300} type="dark" effect="solid" />
                                            </div>
                                        })}
                                    </div>
                                    <div className="tip">{totalReadyDeviceCount} уствойств готово к принятию пушей из {totalDeviceCount} инслаллов</div>
                                    </div>
                                </li>
                                }

                                <li className="field">
                                    <div><label htmlFor="title">Заголовок<span className="required"/></label></div>
                                    <div className="tip">Отображаеться пользователю</div>
                                    <div><input id="title" style={{ paddingRight: '40px' }} className="emoji-text" name="title" type="text" onChange={this.onFieldChanged("title")} required value={this.state.title}/></div>
                                    <div className="holder">
                                        <div className="holder wide inRow rightAlign">
                                            <button type="button" style={{top: '-19px', right: '4px'}} className="emoji-btn small floating" onClick={(e) => {e.stopPropagation(); this.showEmojiPicker("title")}}><div className="icon emoji"/></button>
                                        </div>
                                        {showEmojiPicker === 'title' && <div className="holder wide inRow rightAlign"><EmojiPicker onClose={this.showEmojiPicker.bind(this,"")} onEmojiSelect={_this.onEmojiSelect.bind(_this, 'title')}/></div>}
                                    </div>
                                    {renderError("title")}
                                </li>

                                <li className="field">
                                    <div><label htmlFor="message">Сообщение<span className="required"/></label></div>
                                    <div className="tip">Отображаеться пользователю</div>
                                    <div><Textarea style={{ minHeight: '47px', paddingRight: '40px' }} id="message" className="wide emoji-text" name="message" maxLength="1024" onChange={this.onFieldChanged("message")} required value={this.state.message}/></div>
                                    <div className="holder">
                                        <div className="holder wide inRow rightAlign">
                                            <button type="button" style={{top: '-27px', right: '4px'}} className="emoji-btn small floating" onClick={(e) => {e.stopPropagation(); this.showEmojiPicker("message")}}><div className="icon emoji"/></button>
                                        </div>
                                        {showEmojiPicker === 'message' && <div className="holder wide inRow rightAlign"><EmojiPicker onClose={this.showEmojiPicker.bind(this,"")} onEmojiSelect={_this.onEmojiSelect.bind(_this, 'message')}/></div>}
                                    </div>
                                    {renderError("message")}
                                </li>

                                {/*<li className="field">
                                    <div>Звук</div>
                                    <div>
                                        <div className="inRow leftAlign">
                                            <select id="sound" name="sound" onChange={this.onSoundChanged.bind(this)} required style={{width: '180px'}} value={selectedSoundID}>
                                                {PUSH_SOUNDS.map(sound => <option key={sound.id} value={sound.id}>{sound.name}</option>)}
                                            </select>
                                            {selectedSoundID && selectedSoundID !== 'default' ?
                                            <AudioPlayer url={`/sounds/${selectedSoundID}.wav`}/>
                                            : <div className="tip">определяется устройством</div>
                                            }
                                        </div>
                                    </div>
                                    {renderError("sound")}
                                </li>*/}

                                <li className="field">
                                    <div>Время отправки</div>
                                    <div>
                                        <DatePicker
                                            minDate={new Date()}
                                            selected={this.state.timeStart}
                                            onChange={this.onTimeStartChanged}
                                            showTimeSelect
                                            timeIntervals='15'
                                            locale='ru'
                                            dateFormat='Pp'
                                            timeCaption='Время'
                                        />
                                    </div>
                                    { this.state.timeStart &&
                                    <div className="tip">{ timeSendDesc() }</div>
                                    }
                                    {renderError("timeStart")}
                                </li>

                                <li className="submit" style={{marginTop: 16}}>
                                    <div className="inCol">
                                        {!totalReadyDeviceCount &&
                                        <div className="warning-msg" style={{marginBottom: '10px'}}>Не выбрано ни одного устройства, но вы все равно можете создать пуш чтобы потом его дублировать когда устройства появятся</div>
                                        }
                                        <div><Button type='submit' title='Отправить' fullFill withoutRounded/></div>
                                    </div>
                                </li>
                            </ul>
                            {renderError("common")}
                        </form>
                    </div>
                </div>
                <div className="footer" style={{marginTop: 16}}>
                    <div/>
                    <Link to="/"><Button withoutRounded variant='warning' title='Назад'/></Link>
                    <div/>
                </div>
            </div>

        );
    }
}

export default props => <CreateNewPush location={useLocation()} navigate={useNavigate()} {...props}/>;