import React, { useEffect, useState } from "react";
import Tabs from "../../components/UI/Tabs";
import Description from "./Description";
import "./style.css";
import Button from "../../components/UI/Button";
import Comments from "./Comments";
import Loader from "../../components/UI/Loader";
import { useLocation, useNavigate } from "react-router-dom";
import queryString from "query-string";
import InputWithLabel from "../../components/UI/InputWithLabel";
import Settings from "./Settings";
import { StoreID } from "../../common/commonTypes";
import axios from "axios";
import { INIT_DATA, LANGUAGES } from "../../constants/pwa";
import { compareObjects, getResponseErrorsList, validationPwaData } from "../../util/util";
import { useBlocker } from "../../hooks/useBlocker";
import Alert from "../../components/UI/Alert";
import ArrowLeft from "../../components/icons/ArrowLeft";
import WarningModal from "../../components/UI/WarningModal";

const initConfirmModalState = {
    isVisible: false,
    onClose: () => {},
    onConfirm: () => {},
};

const CreateEditPwa = ({ isEditMode }) => {
    const [data, setData] = useState(isEditMode ? null : INIT_DATA);
    const [initialData, setInitialData] = useState(null);
    const [isDisabledBtn, setIsDisabledBtn] = useState(false);
    const [languagesList, setLanguagesList] = useState(null);
    const [templates, setTemplates] = useState(null);
    const [templatesDesc, setTemplatesDesc] = useState(null);
    const [activeTab, setActiveTab] = useState("Описание");
    const [errorData, setErrorData] = useState(null);
    const [lastUpdated, setLastUpdated] = useState(null);
    const [alert, setAlert] = useState({
        isVisible: false,
        variant: "success",
        message: "",
    });
    const [confirmModal, setConfirmModal] = useState(initConfirmModalState);

    const showAlert = (variant, message) => {
        setAlert({ isVisible: true, variant, message });
    };

    const updateData = ({ keys, value, type, isNotUpdateLastUpdatedTime }) => {
        setData((prev) => {
            const newData = { ...prev };
            let temp = newData;

            for (let i = 0; i < keys.length - 1; i++) {
                const keyItem = keys[i];
                temp[keyItem] = { ...temp[keyItem] };
                temp = temp[keyItem];
            }

            if (type === "delete") {
                delete temp[keys[keys.length - 1]];
            } else {
                temp[keys[keys.length - 1]] = value;
            }

            if (isNotUpdateLastUpdatedTime) {
                return newData;
            }

            if (!compareObjects(newData, initialData)) {
                setLastUpdated((prev) => (prev ? prev : Date.now()));
            } else {
                setLastUpdated(null);
            }

            return newData;
        });
    };

    const location = useLocation();
    const navigate = useNavigate();

    const createErrorMessage = (value) => {
        setErrorData(value);
        window.scrollTo({
            top: document.documentElement.scrollHeight,
            behavior: "smooth",
        });

        setTimeout(() => {
            setErrorData(null);
        }, 3000);
    };

    const onSubmit = async ({ updatedData, callback }) => {
        try {
            const errorMessage = validationPwaData(data);

            if (errorMessage) {
                throw errorMessage;
            }

            const sendData = {};

            let dataCopy = updatedData ? { ...updatedData } : { ...data };
            setInitialData(JSON.parse(JSON.stringify(dataCopy)));

            sendData.name = dataCopy.name;
            delete dataCopy.name;
            delete dataCopy.default_pwa_template;

            if (dataCopy.id) {
                sendData.app_id = dataCopy.id;
                delete dataCopy.id;
            }
            sendData.appType = StoreID.PWA;

            sendData.store_info = JSON.stringify(dataCopy);

            setIsDisabledBtn(true);
            if (!sendData.app_id) {
                const { data } = await axios.post("/newapp", sendData);
                updateData({ keys: ["id"], value: data.app_id });
            } else {
                await axios.post("/updateapp", sendData);
            }
            setLastUpdated(null);
            showAlert("success", "Сохранено");

            if (callback) {
                setIsDisabledBtn(false);
                return callback();
            }
        } catch (err) {
            if (err && !err.tab) {
                createErrorMessage(getResponseErrorsList(err));
            } else {
                setActiveTab(err.tab);
                setErrorData(err);
            }
        }

        setIsDisabledBtn(false);
    };

    const tabItems = [
        {
            label: "Описание",
            children: (
                <Description
                    data={data}
                    updateData={updateData}
                    languagesList={languagesList}
                    templatesDesc={templatesDesc}
                    createErrorMessage={createErrorMessage}
                />
            ),
        },
        {
            label: "Комментарии",
            children: (
                <Comments
                    data={data}
                    updateData={updateData}
                    languagesList={languagesList}
                    createErrorMessage={createErrorMessage}
                    errorData={errorData}
                />
            ),
        },

        {
            label: "Настройки",
            children: <Settings data={data} updateData={updateData} templates={templates} />,
        },
    ];

    useEffect(() => {
        const getLanguages = async () => {
            try {
                const { data } = await axios.post("/app_allowed_languages");
                const languages = {};

                data.forEach((lang) => {
                    if (LANGUAGES[lang]) {
                        languages[lang] = LANGUAGES[lang];
                    }
                });
                setLanguagesList(languages);
            } catch (err) {
                createErrorMessage(getResponseErrorsList(err));
            }
        };

        const getData = async () => {
            try {
                const {
                    data: { pwa_templates, default_pwa_template },
                } = await axios.post("/newapp_data");
                setTemplates(
                    pwa_templates.map((name) => ({
                        label: name,
                        value: name,
                    }))
                );

                const pwaData = { ...INIT_DATA, default_pwa_template };
                if (default_pwa_template) {
                    pwaData.template = default_pwa_template;
                } else if (!pwa_templates.includes(pwaData.template)) {
                    pwaData.template = pwa_templates[0];
                }

                setInitialData(pwaData);
                setData(pwaData);
                document.getElementById("service-name")?.focus();
            } catch (err) {
                createErrorMessage(getResponseErrorsList(err));
            }
        };

        const getTemplatesDesc = async () => {
            try {
                const { data } = await axios.post("/pwa_auto_description_templates");
                setTemplatesDesc(
                    data.map(({ template, tip }) => ({
                        label: template,
                        value: template,
                        tip,
                    }))
                );
            } catch (err) {
                createErrorMessage(getResponseErrorsList(err));
            }
        };

        const getPwaData = async () => {
            try {
                const { id } = queryString.parse(location.search);
                const {
                    data: { app, pwa_templates, default_pwa_template },
                } = await axios.post("/editapp_data", {
                    app_id: id,
                });

                let pwa = {
                    id: app.id,
                    name: app.name,
                    push_system: "push_express",
                    default_pwa_template,
                };

                if (app.store_info) {
                    pwa = { ...pwa, ...JSON.parse(app.store_info) };
                }

                if (!pwa.seed) {
                    pwa.seed = Date.now();
                }

                if (pwa.tags) {
                    delete pwa.tags;
                }

                if (default_pwa_template) {
                    pwa.template = default_pwa_template;
                } else if (!pwa_templates.includes(pwa.template)) {
                    pwa.template = pwa_templates[0];
                }

                setTemplates(
                    pwa_templates.map((name) => ({
                        label: name,
                        value: name,
                    }))
                );
                setInitialData({ ...INIT_DATA, ...pwa });
                setData({ ...INIT_DATA, ...pwa });
            } catch (err) {
                createErrorMessage(getResponseErrorsList(err));
            }
        };

        if (isEditMode) {
            getPwaData();
        } else {
            getData();
        }
        getLanguages();
        getTemplatesDesc();
    }, []);

    const blocker = async (retry) => {
        setConfirmModal({
            isVisible: true,
            onClose: () => {
                setConfirmModal(initConfirmModalState);
                retry();
            },
            onConfirm: async () => {
                await onSubmit({ callback: retry });
                setConfirmModal(initConfirmModalState);
            },
        });
    };

    useBlocker(blocker, !compareObjects(data, initialData, ["id"]));

    const changeActiveTab = (newTab) => {
        if (
            !lastUpdated ||
            Date.now() - lastUpdated < 40000 ||
            compareObjects(data, initialData, ["name", "icon", "screens"])
        ) {
            setActiveTab(newTab);
            return;
        }

        setConfirmModal({
            isVisible: true,
            onClose: () => {
                setActiveTab(newTab);
                setConfirmModal(initConfirmModalState);
            },
            onConfirm: async () => {
                await onSubmit({ callback: () => setActiveTab(newTab) });
                setConfirmModal(initConfirmModalState);
            },
        });
    };

    if (!data) {
        return <Loader />;
    }

    return (
        <div className="page create-edit-pwa with-preview" id="create-edit-pwa">
            <Alert
                variant={alert.variant}
                message={alert.message}
                isVisible={alert.isVisible}
                onClose={() => setAlert({ ...alert, isVisible: false })}
            />
            <WarningModal
                isVisible={confirmModal.isVisible}
                onClose={confirmModal.onClose}
                anchorContainerID="create-edit-pwa"
                onConfirm={confirmModal.onConfirm}
                text="Данные не сохранены. Сохранить?"
                isLoading={isDisabledBtn}
                confirmText="Да"
            />
            <div className="create-edit-pwa-header">
                <Button
                    title={
                        <>
                            <ArrowLeft /> <span>Вернуться к списку PWA</span>
                        </>
                    }
                    customClassName="go-to-back-btn"
                    variant="warning"
                    size="small"
                    withoutRounded
                    onClick={() => navigate("/apps")}
                />
                <h1>{isEditMode ? "Изменить" : "Создать"} PWA</h1>
                <div />
            </div>

            <div className="card">
                <div className="four-fields">
                    <InputWithLabel
                        label="Название в сервисе"
                        value={data.name}
                        inputId="service-name"
                        fieldName="name"
                        onChange={(key, value) => updateData({ keys: [key], value })}
                    />
                </div>
                <Tabs items={tabItems} setActiveTab={changeActiveTab} activeTab={activeTab} />

                <div className="footer-block filed-block">
                    <Button
                        withoutRounded
                        title="Сохранить"
                        onClick={() => onSubmit({})}
                        isLoading={isDisabledBtn}
                        fullFill
                    />
                    {errorData && !errorData.tab && (
                        <div className="error-block">{Object.values(errorData).map((err) => err)}</div>
                    )}
                </div>
            </div>
        </div>
    );
};

export default CreateEditPwa;
