import { AgeCategory } from "../common/commonTypes";

export const PREVIEW_URL = "/pwa_preview/";
export const PREVIEW_DESCRIPTIONS_KEY = "pwa_preview_descriptions";
export const PREVIEW_COMMENTS_KEY = "pwa_preview_comments";

export const PREVIEW_THAMES = {
    dark: "dark",
    light: "light",
};

export const LANGUAGES = {
    ab: "Абхазский | ab",
    av: "Аварский | av",
    ae: "Авестийский | ae",
    az: "Азербайджанский | az",
    ay: "Аймара | ay",
    ak: "Акан | ak",
    sq: "Албанский | sq",
    am: "Амхарский | am",
    en: "Английский | en",
    ar: "Арабский | ar",
    as: "Ассамский | as",
    aa: "Афарский | aa",
    af: "Африкаанс | af",
    bm: "Бамбара | bm",
    eu: "Баскский | eu",
    ba: "Башкирский | ba",
    be: "Белорусский | be",
    bn: "Бенгальский | bn",
    my: "Бирманский | my",
    bi: "Бислама | bi",
    bg: "Болгарский | bg",
    bs: "Боснийский | bs",
    br: "Бретонский | br",
    cy: "Валлийский | cy",
    hu: "Венгерский | hu",
    ve: "Венда | ve",
    vo: "Волапюк | vo",
    wo: "Волоф | wo",
    vi: "Вьетнамский | vi",
    gl: "Галисийский | gl",
    lg: "Ганда | lg",
    hz: "Гереро | hz",
    kl: "Гренландский | kl",
    el: "Греческий (новогреческий) | el",
    ka: "Грузинский | ka",
    gn: "Гуарани | gn",
    gu: "Гуджарати | gu",
    gd: "Гэльский | gd",
    da: "Датский | da",
    dz: "Дзонг-кэ | dz",
    dv: "Дивехи (Мальдивский) | dv",
    zu: "Зулу | zu",
    he: "Иврит | he",
    ig: "Игбо | ig",
    yi: "Идиш | yi",
    id: "Индонезийский | id",
    ia: "Интерлингва | ia",
    ie: "Интерлингве | ie",
    iu: "Инуктитут | iu",
    ik: "Инупиак | ik",
    ga: "Ирландский | ga",
    is: "Исландский | is",
    es: "Испанский | es",
    it: "Итальянский | it",
    yo: "Йоруба | yo",
    kk: "Казахский | kk",
    kn: "Каннада | kn",
    kr: "Канури | kr",
    ca: "Каталанский | ca",
    ks: "Кашмири | ks",
    qu: "Кечуа | qu",
    ki: "Кикуйю | ki",
    kj: "Киньяма | kj",
    ky: "Киргизский | ky",
    zh: "Китайский | zh",
    kv: "Коми | kv",
    kg: "Конго | kg",
    ko: "Корейский | ko",
    kw: "Корнский | kw",
    co: "Корсиканский | co",
    xh: "Коса | xh",
    ku: "Курдский | ku",
    km: "Кхмерский | km",
    lo: "Лаосский | lo",
    la: "Латинский | la",
    lv: "Латышский | lv",
    ln: "Лингала | ln",
    lt: "Литовский | lt",
    lu: "Луба-катанга | lu",
    lb: "Люксембургский | lb",
    mk: "Македонский | mk",
    mg: "Малагасийский | mg",
    ms: "Малайский | ms",
    ml: "Малаялам | ml",
    mt: "Мальтийский | mt",
    mi: "Маори | mi",
    mr: "Маратхи | mr",
    mh: "Маршалльский | mh",
    me: "Мерянский | me",
    mn: "Монгольский | mn",
    gv: "Мэнский (Мэнкский) | gv",
    nv: "Навахо | nv",
    na: "Науру | na",
    nd: "Ндебеле северный | nd",
    nr: "Ндебеле южный | nr",
    ng: "Ндунга | ng",
    de: "Немецкий | de",
    ne: "Непальский | ne",
    nl: "Нидерландский (Голландский) | nl",
    no: "Норвежский | no",
    ny: "Ньянджа | ny",
    nn: "Нюнорск (новонорвежский) | nn",
    oj: "Оджибве | oj",
    oc: "Окситанский | oc",
    or: "Ория | or",
    om: "Оромо | om",
    os: "Осетинский | os",
    pi: "Пали | pi",
    pa: "Пенджабский | pa",
    fa: "Персидский | fa",
    pl: "Польский | pl",
    pt: "Португальский | pt",
    ps: "Пушту | ps",
    rm: "Ретороманский | rm",
    rw: "Руанда | rw",
    ro: "Румынский | ro",
    rn: "Рунди | rn",
    ru: "Русский | ru",
    sm: "Самоанский | sm",
    sg: "Санго | sg",
    sa: "Санскрит | sa",
    sc: "Сардинский | sc",
    ss: "Свази | ss",
    sr: "Сербский | sr",
    si: "Сингальский | si",
    sd: "Синдхи | sd",
    sk: "Словацкий | sk",
    sl: "Словенский | sl",
    so: "Сомали | so",
    st: "Сото южный | st",
    sw: "Суахили | sw",
    su: "Сунданский | su",
    tl: "Тагальский | tl",
    tg: "Таджикский | tg",
    ty: "Таитянский | ty",
    th: "Тайский | th",
    ta: "Тамильский | ta",
    tt: "Татарский | tt",
    tw: "Тви | tw",
    te: "Телугу | te",
    bo: "Тибетский | bo",
    ti: "Тигринья | ti",
    to: "Тонганский | to",
    tn: "Тсвана | tn",
    ts: "Тсонга | ts",
    tr: "Турецкий | tr",
    tk: "Туркменский | tk",
    uz: "Узбекский | uz",
    ug: "Уйгурский | ug",
    uk: "Украинский | uk",
    ur: "Урду | ur",
    fo: "Фарерский | fo",
    fj: "Фиджи | fj",
    fl: "Филиппинский | fl",
    fi: "Финский (Suomi) | fi",
    fr: "Французский | fr",
    fy: "Фризский | fy",
    ff: "Фулах | ff",
    ha: "Хауса | ha",
    hi: "Хинди | hi",
    ho: "Хиримоту | ho",
    hr: "Хорватский | hr",
    cu: "Церковнославянский (Старославянский) | cu",
    ch: "Чаморро | ch",
    ce: "Чеченский | ce",
    cs: "Чешский | cs",
    za: "Чжуанский | za",
    cv: "Чувашский | cv",
    sv: "Шведский | sv",
    sn: "Шона | sn",
    ee: "Эве | ee",
    eo: "Эсперанто | eo",
    et: "Эстонский | et",
    jv: "Яванский | jv",
    ja: "Японский | ja",
};

export const ANDROID_POPUP_OPTIONS = [
    {
        value: true,
        label: "Включенный",
    },
    {
        value: false,
        label: "Выключенный"
    }
];

export const MAX_LANGUAGES = 40;

export const CATEGORIES_PWA = [
    {
        value: "apps",
        label: "Приложения (зеленый)",
    },
    {
        value: "games",
        label: "Игры (синий)",
    },
];

export const TYPE_PAGE_OPTIONS = {
    WEB: { label: "GooglePlay Web (зеленая)", value: "web" },
    APP: { label: "GooglePlay App (синяя)", value: "app" },
}

export const TAGS_PWA = [
    {
        value: "Casino",
        label: "Casino",
    },
    {
        value: "Plinko",
        label: "Plinko",
    },
    {
        value: "Slots",
        label: "Slots",
    },
    {
        value: "Sweet Bonanza",
        label: "Sweet Bonanza",
    },
    {
        value: "Aviator",
        label: "Aviator",
    },
];

export const INIT_DESC_PWA = {
    title: "",
    developer: "",
    icon: "",
    screens: [],
    desc: "",
    news: "",
    videoUrl: "",
    windowInstallType: "standard",
    windowInstallDesc: "",
};

export const INIT_COMMENT_PWA = {
    avatar: "",
    auth: "",
    comment: "",
    date: "0",
    like: 0,
    rating: "5",
    resComment: "",
    answerType: "absent",
};

export const REQUIRED_FIELDS_IN_COMMENTS = [
    "auth",
    "comment",
    "like",
    "rating",
];

export const MAX_LIMIT_COMMENTS = 40;

export const RANGE_RANDOM_LIKE = { min: 200, max: 500 };

export const DATE_SELECT_OPTIONS = [
    { value: "0", label: "Сегодня" },
    { value: "1", label: "Вчера" },
    { value: "2", label: "Позавчера" },
    { value: "3", label: "3 дня назад" },
    { value: "4", label: "4 дня назад" },
    { value: "5", label: "5 дней назад" },
    { value: "6", label: "6 дней назад" },
    { value: "7", label: "7 дней назад" },
    { value: "8", label: "8 дней назад" },
    { value: "9", label: "9 дней назад" },
    { value: "10", label: "10 дней назад" },
    { value: "11", label: "11 дней назад" },
    { value: "12", label: "12 дней назад" },
    { value: "13", label: "13 дней назад" },
    { value: "14", label: "14 дней назад" },
    { value: "15", label: "15 дней назад" },
    { value: "16", label: "16 дней назад" },
    { value: "17", label: "17 дней назад" },
    { value: "18", label: "18 дней назад" },
    { value: "19", label: "19 дней назад" },
    { value: "20", label: "20 дней назад" },
    { value: "21", label: "21 день назад" },
    { value: "22", label: "22 дня назад" },
    { value: "23", label: "23 дня назад" },
    { value: "24", label: "24 дня назад" },
    { value: "25", label: "25 дней назад" },
    { value: "25+", label: "> 25 дней назад" },
];

export const BUTTONS_TYPE = [
    { label: "Стандартная кнопка", value: "1" },
    { label: "Check device...", value: "2" },
    { label: "Spinner", value: "3" },
];

export const DOWNLOAD_OPTIONS = [
    {
        label: "Стандартный",
        value: "standard",
        desc: "1.3 MB of 7.2 MB",
    },
    {
        label: "В процентах",
        value: "percent",
        desc: "10% of 7.2 MB",
    }
];

export const REDIRECTS_OPTIONS = [
    {
        label: "Standard",
        value: "1",
        desc: (
            <span>
                Отображается системное окно "Продолжить/Вернуться" при нажатии
                на кнопку "Установить".
            </span>
        ),
    },
    {
        label: "White Hint",
        value: "2",
        desc: (
            <span>
                Автоматически отображается системное окно перенаправления в
                системный браузер. <br />
                Если выбрано "Вернуться", экран подсвечивается, и появляется
                надпись "Tap to continue".
            </span>
        ),
    },
    {
        label: "Auto Display Redirect",
        value: "3",
        desc: (
            <span>
                ФБ-браузер сразу пытается перенаправить в системный браузер.
                <br />
                Отображается системное окно "Продолжить/Вернуться".
            </span>
        ),
    },
];

export const INIT_DATA = {
    rating: "4.8",
    comments: {},
    descriptions: {},
    reviews: "7510",
    age: AgeCategory.ADULTS_18_PLUS,
    download: "101000",
    btnDownSize: "7.3",
    template: "main_pwa",
    installBtnVersion: "1",
    redirectVersion: "1",
    lastUpdated: "0",
    showHeaderMenu: true,
    seed: Date.now(),
    push_system: "da",
    downloadVersion: "standard",
    display_btn_play: "after_4_seconds",
    typePage: "web",
    withAndroidPopup: false,
};

export const DISPLAY_PLAY_BTN_OPTIONS = [
    {
        label: "После установки",
        value: "after_installation",
    },
    {
        label: "Через 4 секунды",
        value: "after_4_seconds",
    },
]

export const WINDOW_INSTALL_OPTIONS = [
    {
        label: "Стандартный",
        value: "standard",
    },
    {
        label: "С скриншотом",
        value: "withScreens",
    },
];

export const DISALLOWED_NUMBER_SYMBOLS = ["e", "+", "-"];

export const ANSWER_OPTIONS = [
    {
        label: "Отсутствует",
        value: "absent",
    },
    {
        label: "Автоматический",
        value: "auto",
    },
    {
        label: "Свой вариант",
        value: "custom",
    },
];

export const AGE_OPTIONS = [
    {
        label: "3+ (all)",
        value: AgeCategory.ALL_3_PLUS,
    },
    {
        label: "7+ (child)",
        value: AgeCategory.CHILDREN_7_PLUS,
    },
    {
        label: "12+ (teens)",
        value: AgeCategory.TEENS_12_PLUS,
    },
    {
        label: "16+ (youth)",
        value: AgeCategory.YOUTH_16_PLUS,
    },
    {
        label: "18+ (adult)",
        value: AgeCategory.ADULTS_18_PLUS,
    },
];

export const SYSTEM_PUSH_OPTIONS = [
    { label: "DAPush", value: "da" },
    { label: "PushExpress", value: "push_express" },
];
