import { useContext, useRef, useState } from "react";
import { Link } from "react-router-dom";
import { Tooltip as ReactTooltip } from "react-tooltip";
import {BackgroundJobState, UserGroup} from "../../common/commonTypes";
import { formatDateTime } from "../../common/commonUtil";
import EditIcon from "../../components/icons/EditIcon";
import StatsIcon from "../../components/icons/StatsIcon";
import RouteItemContent from "./RouteItemContent";
import WarningIcon from "../../components/icons/WarningIcon";
import ArrowUpIcon from "../../components/icons/ArrowUpIcon";
import { UserContext } from "../../context/User";

const Spinner = ({ id }) => (
    <div
        className="spinner"
        style={{ opacity: 0.6, cursor: "pointer" }}
        data-tooltip-content="Генерация Whitepage..."
        data-tooltip-id={"tooltipUpdatingRoute" + id}
    >
        <div></div>
    </div>
);

const WhitepageGenerationFailedIcon = ({ id }) => (
    <div className="warning-icon"
        data-tooltip-content="Сбой генерации Whitepage"
        data-tooltip-id={"tooltipUpdatingRoute" + id}>
            <WarningIcon />
    </div>
)

const RouteItem = ({
    id,
    appType,
    name,
    createdAt,
    generateWhitepageState,
    domains,
    games,
    geo,
    naming,
    routeLink,
    targetLink,
    installCount,
    wideLinkDesign,
    isNamingOn,
    gpLinksMode,
    onCopy,
    isSelectedForPush,
    onSelectPush
}) => {
    const [isOpen, setIsOpen] = useState(false);
    const contentRef = useRef(null);
    const user = useContext(UserContext);
    const toggleAccordion = () => setIsOpen((prev) => !prev);

    return (
        <div className="route-item" onClick={toggleAccordion}>
            <div className="router-item-header">
                <div className={`route-item-id ${name ? 'with-id' : ""}`}>
                    <div>
                        {(generateWhitepageState === BackgroundJobState.NEW || generateWhitepageState === BackgroundJobState.PROCESSING) &&
                                ( <Spinner id={id} /> )}
                            { generateWhitepageState === BackgroundJobState.FAILED &&
                                ( <WhitepageGenerationFailedIcon id={id} /> )}
                        <span>#{id}</span>
                    </div>
                </div>
                <div className="router-item-header-content">
                    <div className="route-item-date-name">
                        <div className="route-item-date">
                            {formatDateTime(createdAt)}
                        </div>
                        <div className="route-item-name break-all">
                            {name || "#" + id}
                        </div>
                    </div>
                </div>
                <div className="route-item-content-desktop">
                    <RouteItemContent
                        id={id}
                        domains={domains}
                        geo={geo}
                        wideLinkDesign={wideLinkDesign}
                        games={games}
                        appType={appType}
                        isNamingOn={isNamingOn}
                        naming={naming}
                        gpLinksMode={gpLinksMode}
                        routeLink={routeLink}
                        targetLink={targetLink}
                        onCopy={onCopy}
                    />
                </div>
                <div className="route-item-actions">
                    {user?.hasPermission(UserGroup.DEVELOPER) && (
                            <button
                                className={"push-btn " + (isSelectedForPush ? "active" : "")}
                                data-tooltip-content="Выбрать для пуша"
                                data-tooltip-id={"tooltipPush" + id}
                                onClick={onSelectPush}
                            >
                                <div className="push-icon-wrapper">
                                    <ArrowUpIcon />
                                </div>
                            </button>
                        )}
                    <Link
                        to={`/editflow/?id=${id}`}
                        data-tooltip-delay-show={100}
                        data-tooltip-content="Редактировать"
                        data-tooltip-id={"tooltipEdit" + id}
                    >
                        <EditIcon />
                    </Link>
                    <Link
                        to={`/stats/?id=${id}`}
                        data-tooltip-content="Статистика"
                        data-tooltip-id={"tooltip" + id}
                    >
                        <span className={installCount === 0 ? "zero" : ""}>
                            {installCount}
                        </span>
                        <StatsIcon />
                    </Link>
                </div>
            </div>
            <div
                className="route-item-content-mobile"
                ref={contentRef}
                style={{
                    height: isOpen
                        ? contentRef.current?.scrollHeight + "px"
                        : 0,
                }}
            >
                <RouteItemContent
                    id={id}
                    domains={domains}
                    geo={geo}
                    wideLinkDesign={wideLinkDesign}
                    games={games}
                    appType={appType}
                    isNamingOn={isNamingOn}
                    naming={naming}
                    gpLinksMode={gpLinksMode}
                    routeLink={routeLink}
                    targetLink={targetLink}
                    createdAt={createdAt}
                    onCopy={onCopy}
                />
            </div>
            <ReactTooltip id={"tooltipPush" + id} type="light" effect="solid" />
            <ReactTooltip id={"tooltip" + id} type="light" effect="solid" />
            <ReactTooltip id={"tooltipEdit" + id} type="light" effect="solid"  />
            <ReactTooltip id={"tooltipUpdatingRoute" + id} effect="solid" />
        </div>
    );
};

export default RouteItem;
