import React, { Component } from 'react';
import axios from "axios";
import '../../css/table.css';
import {formatCurrency, isEmpty} from "../../util/util";
import {CopyToClipboard} from "react-copy-to-clipboard";
import Loader from '../../components/UI/Loader';

class CompanyStats extends Component {
    // Initialize the state
    constructor(props){
        super(props);
        this.state = {
            loadingFD: true,
            loading: true,
            data: {}
        }
    }

    // Fetch the list on first mount
    componentDidMount() {
        this.fetchFinanceData();
        this.fetchData();
    }

    async fetchFinanceData() {
        this.setState({loadingFD: true});

        let res = await axios.post('/get_finance_data');

        if (res.data)
            this.setState({loadingFD: false, financeData: res.data});
    }

    async fetchData() {
        this.setState({loading: true});

        let res = await axios.post('/users_stats');

        if (res.data)
            this.setState({loading: false, data: res.data});
    }

    render() {
        const { financeData, data } = this.state;

        let install_count = (count) => {
            if (count >= 1000)
                return <div className="blue">{count}</div>
            if (count >= 300)
                return <div className="green">{count}</div>
            if (count > 0)
                return <div className="weakgreen">{count}</div>
            return <div className="gray">{count}</div>;
        };

        const calc_total = (rows, total_arr, parseFunc) =>  {
            if (rows.length <= 1) return;
            rows.forEach(row => {
                for (let key in row) {
                    if (key in total_arr)
                        total_arr[key] += parseFunc(row[key]);
                    else
                        total_arr[key] = parseFunc(row[key]);
                }
            });
        }
        let install_stats_total = {};
        let finance_stats_total = {};
        if (data) {
            if (data.install_stats) calc_total(data.install_stats, install_stats_total, parseInt);
            if (data.finance_stats) calc_total(data.finance_stats, finance_stats_total, parseFloat);
        }

        if(!data?.install_stats || !data?.finance_stats || !financeData) {
            return <div className='page'>
                <h1>Статистика компании</h1>
                <Loader/>
            </div>
        }

        return (
            <div className="page">
                <h1>Статистика компании</h1>

                <h2>Тарифы:</h2>
                {financeData && (
                    <>
                        <div className="table-responsive perfect-scrollbar">
                            <table className="table">
                                <thead>
                                    <tr>
                                        <th>Цена инсталла</th>
                                        <th>при положительном балансе</th>
                                        <th>за кредитные средства</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td className="semibold nowrap">
                                            PWA:
                                        </td>
                                        <td>
                                            {formatCurrency(
                                                financeData.pwaInstallPriceWithPositiveBalance,
                                                3
                                            )}
                                        </td>
                                        <td>
                                            {formatCurrency(
                                                financeData.pwaInstallPriceWithNegativeBalance,
                                                3
                                            )}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="semibold nowrap">
                                            iOS:
                                        </td>
                                        <td>
                                            {formatCurrency(
                                                financeData.iosInstallPriceWithPositiveBalance,
                                                3
                                            )}
                                        </td>
                                        <td>
                                            {formatCurrency(
                                                financeData.iosInstallPriceWithNegativeBalance,
                                                3
                                            )}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="semibold nowrap">
                                            Android:
                                        </td>
                                        <td>
                                            {formatCurrency(
                                                financeData.installPriceWithPositiveBalance,
                                                3
                                            )}
                                        </td>
                                        <td>
                                            {formatCurrency(
                                                financeData.installPriceWithNegativeBalance,
                                                3
                                            )}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="semibold nowrap">
                                            External link redirect:
                                        </td>
                                        <td>
                                            {formatCurrency(
                                                financeData.universalInstallPriceWithPositiveBalance,
                                                3
                                            )}
                                        </td>
                                        <td>
                                            {formatCurrency(
                                                financeData.universalInstallPriceWithNegativeBalance,
                                                3
                                            )}
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        {/*<li>
                        <div className='rightAlign smallerText'>Поддержка пушей на устройстве при инсталле при положительном балансе:</div>
                        <div><b>{formatCurrency(financeData.extraChargeForPushSupportDisc,3)}</b></div>
                    </li>
                    <li>
                        <div className='rightAlign smallerText'>Поддержка пушей на устройстве при инсталле за кредитные средства:</div>
                       <div><b>{formatCurrency(financeData.extraChargeForPushSupport,3)}</b></div>
                    </li>*/}
                        {parseFloat(financeData.credit_limit) > 0.0 && (
                            <div className="credit-limit">
                                <div>Ваш кредитный лимит:</div>
                                <div>
                                    <b>
                                        {formatCurrency(
                                            financeData.credit_limit
                                        )}
                                    </b>
                                </div>
                            </div>
                        )}
                    </>
                )}
                {/* </div> */}
                <h2 style={{marginTop: '40px'}}>Инсталлы</h2>
                {data && data.install_stats && (
                    <div className="table-responsive perfect-scrollbar">
                        <table className="table">
                            <thead>
                                <tr>
                                    <th>Пользователь</th>
                                    <th>За 24 часа</th>
                                    <th>За сегодня</th>
                                    <th>За вчера</th>
                                    <th>За позавчера</th>
                                    <th>Всего</th>
                                </tr>
                            </thead>
                            <tbody>
                                {data.install_stats.map((row) => (
                                    <tr key={row.name}>
                                        <td>{row.name}</td>
                                        <td>
                                            {install_count(row._24h_change)}
                                        </td>
                                        <td>
                                            {install_count(row.today_change)}
                                        </td>
                                        <td>
                                            {install_count(row.day_ago_change)}
                                        </td>
                                        <td>
                                            {install_count(
                                                row._2days_ago_change
                                            )}
                                        </td>
                                        <td>{row.total_installs}</td>
                                    </tr>
                                ))}
                                {!isEmpty(install_stats_total) && (
                                    <tr className="table-footer">
                                        <td>Итого:</td>
                                        <td className="col">
                                            {install_count(
                                                install_stats_total._24h_change
                                            )}
                                        </td>
                                        <td>
                                            {install_count(
                                                install_stats_total.today_change
                                            )}
                                        </td>
                                        <td className="col">
                                            {install_count(
                                                install_stats_total.day_ago_change
                                            )}
                                        </td>
                                        <td className="col">
                                            {install_count(
                                                install_stats_total._2days_ago_change
                                            )}
                                        </td>
                                        <td className="col">
                                            {install_stats_total.total_installs}
                                        </td>
                                    </tr>
                                )}
                            </tbody>
                        </table>
                    </div>
                )}

                <h2>Расходы</h2>
                {data && data.finance_stats && (
                    <div className="table-responsive perfect-scrollbar">
                        <table className="table">
                            <thead>
                                <tr>
                                    <th>Пользователь</th>
                                    <th>За 24 часа</th>
                                    <th>За сегодня</th>
                                    <th>За вчера</th>
                                    <th>За позавчера</th>
                                    <th>Всего</th>
                                </tr>
                            </thead>
                            <tbody>
                                {data.finance_stats.map((row) => (
                                    <tr key={row.name}>
                                        <td>{row.name}</td>
                                        <td>
                                            {formatCurrency(
                                                parseFloat(row._24h_change)
                                            )}
                                        </td>
                                        <td>
                                            {formatCurrency(
                                                parseFloat(row.today_change)
                                            )}
                                        </td>
                                        <td>
                                            {formatCurrency(
                                                parseFloat(row.day_ago_change)
                                            )}
                                        </td>
                                        <td>
                                            {formatCurrency(
                                                parseFloat(
                                                    row._2days_ago_change
                                                )
                                            )}
                                        </td>
                                        <td>
                                            {formatCurrency(
                                                parseFloat(row.total_amount)
                                            )}
                                        </td>
                                    </tr>
                                ))}
                                {!isEmpty(finance_stats_total) && (
                                    <tr className="table-footer">
                                        <td>Итого:</td>
                                        <td>
                                            {formatCurrency(
                                                parseFloat(
                                                    finance_stats_total._24h_change
                                                )
                                            )}
                                        </td>
                                        <td>
                                            {formatCurrency(
                                                parseFloat(
                                                    finance_stats_total.today_change
                                                )
                                            )}
                                        </td>
                                        <td>
                                            {formatCurrency(
                                                parseFloat(
                                                    finance_stats_total.day_ago_change
                                                )
                                            )}
                                        </td>
                                        <td>
                                            {formatCurrency(
                                                parseFloat(
                                                    finance_stats_total._2days_ago_change
                                                )
                                            )}
                                        </td>
                                        <td>
                                            {formatCurrency(
                                                parseFloat(
                                                    finance_stats_total.total_amount
                                                )
                                            )}
                                        </td>
                                    </tr>
                                )}
                            </tbody>
                        </table>
                    </div>
                )}
            </div>
        );
    }
}

export default CompanyStats;
