import { useMemo, useState } from "react";
import Button from "../../components/UI/Button";
import CheckboxWithLabel from "../../components/UI/CheckboxWithLabel";
import { sortLanguages } from "../../util/util";
import SelectWithLabel from "../../components/UI/SelectWithLabel";

const checkIsAutoGenerateDesc = () => {
    const isAutoGenerateDesc = localStorage.getItem("isAutoGenerateDesc");
    if (!isAutoGenerateDesc || isAutoGenerateDesc === "true") return true;

    return false;
};

const getDescTemplate = (templates) => {
    if (!templates) {
        return "";
    }
    const descTemplate = localStorage.getItem("desc_template");

    return (
        templates.find(({ value }) => value === descTemplate)?.value ||
        templates[0]?.value ||
        ""
    );
};

const AddLanguageForm = ({
    currentLanguage,
    onSubmit,
    languagesList,
    isDescFlow,
    templatesDesc,
    isLoading,
}) => {
    const [selectedLanguages, setSelectedLanguages] = useState({
        lngFrom: "",
        lngTo: "",
        isAutoGenerateDesc: checkIsAutoGenerateDesc(),
        template: getDescTemplate(templatesDesc),
    });

    const updateLanguages = (key, value) => {
        if (key === "isAutoGenerateDesc") {
            localStorage.setItem("isAutoGenerateDesc", JSON.stringify(value));
        }
        if (key === "template") {
            localStorage.setItem("desc_template", value);
        }
        setSelectedLanguages((prev) => ({ ...prev, [key]: value }));
    };

    const { optionsFrom, optionsNew } = useMemo(() => {
        const optionsFrom = [];
        const optionsNew = [];

        Object.keys(languagesList).forEach((lng) => {
            const item = { label: languagesList[lng], value: lng };
            if (currentLanguage?.[lng]) {
                optionsFrom.push(item);
            } else {
                optionsNew.push(item);
            }
        });

        return {
            optionsFrom: sortLanguages(optionsFrom),
            optionsNew: sortLanguages(optionsNew),
        };
    }, [currentLanguage]);

    const commentForTemplate = useMemo(() => {
        if (!selectedLanguages.template) {
            return null;
        }

        return templatesDesc.find(
            (temp) => temp.value === selectedLanguages.template
        )?.tip;
    }, [selectedLanguages.template, templatesDesc]);

    return (
        <div className="add-language-form">
            <h2>Добавить язык</h2>
            <SelectWithLabel
                label="Новый язык"
                fieldName="lngTo"
                onChange={updateLanguages}
                value={selectedLanguages.lngTo}
                options={optionsNew}
            />
            {isDescFlow && (
                <CheckboxWithLabel
                    label="Заполнить описание по шаблону"
                    isChecked={selectedLanguages.isAutoGenerateDesc}
                    fieldName="isAutoGenerateDesc"
                    onChange={updateLanguages}
                    labelStyle={{ padding: 0 }}
                />
            )}
            {optionsFrom.length !== 0 &&
                (!selectedLanguages.isAutoGenerateDesc || !isDescFlow) && (
                    // <SelectWithLabel
                    //     label={`Скопировать ${
                    //         isDescFlow ? "описание" : "коментарии"
                    //     } с:`}
                    //     fieldName="lngFrom"
                    //     onChange={updateLanguages}
                    //     value={selectedLanguages.lngFrom}
                    //     options={optionsFrom}
                    // /> [FUTURE]
                    <div style={{ height: isDescFlow ? "78px" : "0px" }}></div>
                )}

            {isDescFlow && selectedLanguages.isAutoGenerateDesc && (
                <>
                    <SelectWithLabel
                        label="Шаблон"
                        fieldName="template"
                        onChange={updateLanguages}
                        value={selectedLanguages.template}
                        options={templatesDesc}
                    />
                    {commentForTemplate && (
                        <div className="tip">{commentForTemplate}</div>
                    )}
                </>
            )}
            <div className="filed-block">
                <Button
                    title="Добавить"
                    withoutRounded
                    isDisabled={!selectedLanguages.lngTo}
                    isLoading={isLoading}
                    onClick={() => onSubmit(selectedLanguages)}
                    fullFill
                />
            </div>
        </div>
    );
};

export default AddLanguageForm;
