import { UserGroup } from "../../common/commonTypes";
import ApplicationIcon from "../icons/ApplicationIcon";
import FacebookSecondIcon from "../icons/FacebookSecondIcon";
import RouteIcon from "../icons/RouteIcon";
import UsersIcon from "../icons/UsersIcon";
import SettingIcon from "../icons/SettingIcon";
import { cabinetNavMenu } from "../../pages/cabinet/CabinetPage";
import { adminPages } from "../../pages/admin/AdminPortal";
import ReportIcon from "../icons/ReportIcon";
import ArrowUpIcon from "../icons/ArrowUpIcon";

const SIDEBAR_ITEMS = [
    {
        title: "Потоки",
        icon: <RouteIcon />,
        accessLevel: true,
        path: "/",
    },

    {
        title: "Приложения",
        icon: <ApplicationIcon />,
        accessLevel: true,
        path: "/apps",
    },

    {
        title: "Пуши",
        icon: <div className="push-icon-wrapper"><ArrowUpIcon/></div>,
        accessLevel: UserGroup.DEVELOPER,
        path: "/push",
    },

    {
        title: "FB pixel",
        icon: <FacebookSecondIcon />,
        accessLevel: true,
        path: "/fbpixel",
    },

    {
        title: "Отчет",
        icon: <ReportIcon />,
        //  HIDE REPORT PAGE FOR SIMPLE USERS
        accessLevel: UserGroup.DEVELOPER,
        path: "/report",
    },

    {
        title: "Кабинет",
        icon: <UsersIcon />,
        accessLevel: true,
        path: "/cabinet",
        children: [...cabinetNavMenu],
    },

    {
        title: "Админ",
        icon: <SettingIcon />,
        accessLevel: UserGroup.DEVELOPER,
        path: "/admin",
        children: [...adminPages],
    },
];

export default SIDEBAR_ITEMS;
