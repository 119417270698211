import React, {useContext, useEffect, useRef} from 'react';
import {UserContext} from "../context/User";
import {formatCurrency} from "../util/util";

let WarnTopPanel = () => {
    const user = useContext(UserContext);
    const warnRef = useRef(null);

    const updateHeight = () => {
        if (warnRef.current) {
            const height = warnRef.current.clientHeight;
            document.body.style.setProperty('--warn-height', height + 'px')
        } else {
            document.body.style.removeProperty('--warn-height')
        }
    };

    useEffect(()=> {
        updateHeight();
        window.addEventListener('resize', updateHeight);
        return () => {
            window.removeEventListener('resize', updateHeight);
        };
    }, [warnRef, user.credit_limit, user.balance])

    const warning = user.credit_limit>0 && user.balance <= -(0.85 * user.credit_limit);
    if (!warning)
        return null;

    const strongWarn = user.credit_limit>0 && user.balance <= -user.credit_limit;

    return (
        <div className="warnTopPanelHolder" ref={warnRef}>
            <div className={ 'warnTopPanel' + (strongWarn ? ' strong' : '')}>
                {strongWarn ?
                    <div>Вы превысили кредитный лимит. Пожалуйста пополните счёт чтобы продолжить работу.</div>
                    :
                    <div>Ваш баланс приближаеться к кредитному лимиту. Рекомендуем пополнить счёт наперед, чтобы все услуги были доступны вовремя.</div>
                }
            </div>
        </div>
    );
};

export default WarnTopPanel;