import React, { useEffect, useMemo, useRef, useState } from "react";
import { useTable, useSortBy, useBlockLayout, useResizeColumns } from "react-table";
import ArrowLeft from "../../components/icons/ArrowLeft";
import { formatDateTime } from "../../common/commonUtil";
import { DEFAULT_COLUMN_WIDTH } from "../../constants/report";
import { adjustColumnsToData, countryCodeToFlagEmoji } from "../../util/report";

function Table({ data, columns, sortBy, order, updateOptions, isLoading, saveColumnWidths }) {
    const [columnWidths, setColumnWidths] = useState({});
    const [isSaveColumnWidths, setIsSaveColumnWidths] = useState(false);
    const [isScrollableRight, setIsScrollableRight] = useState(false);
    const tableWrapperRef = useRef(null);

    const { defaultColumnStyle, visibleColumns } = useMemo(() => {
        const style = {
            minWidth: 30,
            maxWidth: 1000,
        };

        let updatedColumns = [...columns];

        if (data?.length) {
            updatedColumns = adjustColumnsToData(data, columns)
        }

        const widths = {};
        updatedColumns.forEach((col) => {
            if (col.width) {
                widths[col.accessor] = col.width;
            }
        });

        setColumnWidths(widths);

        const visibleColumns = updatedColumns.filter((col) => !col.isHidden);

        const navbar = document.querySelector(".navbar");
        const navbarWidth = navbar.clientWidth;
        const columnWidth = navbarWidth / visibleColumns.length;

        style.width = columnWidth < DEFAULT_COLUMN_WIDTH ? DEFAULT_COLUMN_WIDTH : columnWidth;
        return { defaultColumnStyle: style, visibleColumns };
    }, [columns, window.clientWidth, data]);

    const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable(
        {
            columns: visibleColumns,
            data,
            defaultColumn: defaultColumnStyle,
        },
        useBlockLayout,
        useResizeColumns,
        useSortBy // Добавляем сортировку
    );

    const handlerSortingClick = (name) => {
        updateOptions({ sort_by: name, sort_order: order === "asc" ? "desc" : "asc" });
    };

    const handleColumnResize = (column) => {
        return (e) => {
            e.stopPropagation(); // Не даём ресайзу триггерить сортировку

            const newWidth = column.totalWidth; // Текущая ширина после ресайза
            const accessor = column.id; // Получаем accessor колонки

            // Обновляем state и localStorage
            setColumnWidths((prevWidths) => {
                const updatedWidths = { ...prevWidths, [accessor]: newWidth };
                return updatedWidths;
            });
        };
    };

    const onMouseUpResize = () => {
        setIsSaveColumnWidths(true);
    };

    useEffect(() => {
        if (isSaveColumnWidths) {
            saveColumnWidths(columnWidths);
            setIsSaveColumnWidths(false);
        }
    }, [columnWidths, isSaveColumnWidths]);

    useEffect(() => {
        const checkScroll = () => {
            if (!tableWrapperRef.current) return;
            const { scrollLeft, scrollWidth, clientWidth } = tableWrapperRef.current;
            setIsScrollableRight(scrollWidth > clientWidth && scrollLeft + clientWidth < scrollWidth);
        };

        const updateReportPageWidth = () => {
            const navbar = document.querySelector(".navbar");
            const reportPage = document.querySelector("#report-page");
    
            if (navbar && reportPage) {
                const navbarWidth = navbar.offsetWidth;
                reportPage.style.width = `${navbarWidth}px`;
            }
        };

        const handleResize = () => {
            checkScroll();
            updateReportPageWidth();
        };

        handleResize();

        tableWrapperRef.current?.addEventListener("scroll", checkScroll);
        window.addEventListener("resize", handleResize);
    
        return () => {
            tableWrapperRef.current?.removeEventListener("scroll", checkScroll);
            window.removeEventListener("resize", handleResize);
        };
    }, []);

    return (
        <div className="react-table-wrapper">
            <div ref={tableWrapperRef} className="perfect-scrollbar">
                <div {...getTableProps()} className={`table ${isLoading ? "loading-table" : ""}`}>
                    <div style={{ maxWidth: "100%" }}>
                        {headerGroups.map((headerGroup) => {
                            const { key, ...headerGroupProps } = headerGroup.getHeaderGroupProps();
                            return (
                                <div key={key} {...headerGroupProps} className="tr" >
                                    {headerGroup.headers.map((column) => {
                                        const { key, ...headerProps } = column.getHeaderProps(
                                            column.getSortByToggleProps()
                                        );
                                        const isSorted = column.isSorted;
                                        const sortClass = isSorted
                                            ? column.isSortedDesc
                                                ? "sorted-desc"
                                                : "sorted-asc"
                                            : "";

                                        return (
                                            <div
                                                key={key}
                                                {...headerProps}
                                                className={`th ${sortClass}`}
                                                onClick={() => handlerSortingClick(column.id)}
                                            >
                                                <div className="th-body">
                                                    {column.render("Header")}
                                                    {sortBy === column.id && (
                                                        <span className={`sort-icon ${order}`}>
                                                            <ArrowLeft />
                                                        </span>
                                                    )}
                                                </div>
                                                <div
                                                    {...column.getResizerProps()}
                                                    onClick={handleColumnResize(column)}
                                                    onMouseUp={onMouseUpResize}
                                                    className={`resizer ${column.isResizing ? "isResizing" : ""}`}
                                                >
                                                    <div />
                                                </div>
                                            </div>
                                        );
                                    })}
                                </div>
                            );
                        })}
                    </div>

                    <div {...getTableBodyProps()}>
                        {rows.map((row) => {
                            prepareRow(row);
                            const { key, ...rowProps } = row.getRowProps();

                            return (
                                <div key={key} {...rowProps} className="tr body-tr">
                                    {row.cells.map((cell) => {
                                        const { key, ...cellProps } = cell.getCellProps();
                                        let value = cell.render("Cell");

                                        if (cell.column.id === 'country_flag') {
                                            value = countryCodeToFlagEmoji(cell.value);
                                          }

                                      
                                        return (
                                            <div key={key} {...cellProps} className="td">
                                                {value}
                                            </div>
                                        );
                                    })}
                                </div>
                            );
                        })}
                    </div>
                </div>
            </div>
            <div className="blackout" style={{ opacity: isScrollableRight ? 1 : 0 }} />
        </div>
    );
}

export default Table;
