import Picker from "@emoji-mart/react";
import googleEmoji from "@emoji-mart/data/sets/14/google.json";
import ru from "@emoji-mart/data/i18n/ru.json"; // Файл перевода

const EmojiPicker = ({ onClose, onEmojiSelect }) => {
    return (
        <div className="widget floating" style={{ textAlign: "center", position: "relative", zIndex: 10 }}>
            <Picker
                style={{ position: "absolute", zIndex: 1000, top: "-5px", right: "0px" }}
                set="google"
                i18n={ru}
                emojiSize={20}
                data={googleEmoji}
                perLine={8}
                theme={document.body.classList.contains("light") ? "light" : "dark"}
                sheetSize={32}
                onEmojiSelect={onEmojiSelect}
                onClickOutside={onClose}
            />
        </div>
    );
};

export default EmojiPicker;
