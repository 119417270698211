import { useEffect, useMemo, useState } from "react";
import axios from "axios";
import {
    DATE_SELECT_OPTIONS,
    INIT_COMMENT_PWA,
    MAX_LIMIT_COMMENTS,
    RANGE_RANDOM_LIKE,
} from "../../constants/pwa";
import Comment from "./Comment";
import Button from "../../components/UI/Button";
import LanguagesList from "./LanguagesList";
import { getRandomInt, getResponseErrorsList } from "../../util/util";
import PreviewPWA from "./PreviewPWA";

const scrollToBottom = () => {
    setTimeout(() => {
        window.scrollTo({
            top: document.documentElement.scrollHeight,
            behavior: "smooth",
        });
    }, 0);
};

const Comments = ({
    data,
    updateData,
    languagesList,
    createErrorMessage,
    errorData,
}) => {
    const [isShowLanguagesModal, setIsShowLanguageModal] = useState(false);
    const [isLoadingAddCommentBtn, setIsLoadingAddCommentBtn] = useState(false);
    const [isLoadingAddTenCommentsBtn, setIsLoadingAddTenCommentsBtn] =
        useState(false);
    const [activeLng, setActiveLng] = useState(
        data?.comments ? Object.keys(data?.comments)?.[0] || "" : ""
    );

    const generateComment = async ({ lang, index }) => {
        const sendData = {
            lang,
            index,
            seed: data.seed,
        };
        const res = await axios.post("/pwa_new_comment", sendData);
        const date = DATE_SELECT_OPTIONS[index]?.value || "25+";
        return {
            ...INIT_COMMENT_PWA,
            ...res.data,
            like: getRandomInt(RANGE_RANDOM_LIKE.min, RANGE_RANDOM_LIKE.max),
            date,
        };
    };

    const addTenComments = async () => {
        setIsLoadingAddTenCommentsBtn(true);
        try {
            const startIndex = Object.keys(data.comments[activeLng]).length;
            const countNewComments =
                startIndex > MAX_LIMIT_COMMENTS - 10
                    ? MAX_LIMIT_COMMENTS - startIndex
                    : 10;
            const commentsPromises = [];

            for (
                let i = startIndex;
                i < startIndex + countNewComments;
                i += 1
            ) {
                commentsPromises.push(
                    generateComment({ lang: activeLng, index: i })
                );
            }

            const newComments = await Promise.all(commentsPromises);
            const id = Date.now();
            let isEmpty = false;
            newComments.map((val, index) => {
                if (isEmpty) {
                    return;
                }

                if (!val.auth) {
                    isEmpty = true;
                }
                updateData({
                    keys: ["comments", activeLng, id + index * 2],
                    value: { ...val },
                });
            });
            scrollToBottom();
        } catch (err) {
            createErrorMessage(getResponseErrorsList(err));
        }
        setIsLoadingAddTenCommentsBtn(false);
    };

    const addLanguage = async ({ lngFrom, lngTo }) => {
        try {
            const id = Date.now();
            let newCommentData = {};

            if (!lngFrom) {
                const newComment = await generateComment({
                    lang: lngTo,
                    index: 0,
                });
                newCommentData = {
                    [id]: { ...newComment },
                };
            } else {
                Object.keys(data.comments[lngFrom]).forEach(
                    (comment, index) => {
                        newCommentData[id + index] = {
                            ...data.comments[lngFrom][comment],
                        };
                    }
                );
            }

            updateData({ keys: ["comments", lngTo], value: newCommentData });
            setIsShowLanguageModal(false);
            setActiveLng(lngTo);
        } catch (err) {
            createErrorMessage(getResponseErrorsList(err));
        }
    };

    const deleteLng = (lng) => {
        const commentsCopy = { ...data.comments };
        delete commentsCopy[lng];

        updateData({
            keys: ["comments", lng],
            type: "delete",
            isNotUpdateLastUpdatedTime: true,
        });
    };

    const updateComment = (id, comment, isNotUpdateLastUpdatedTime) => {
        updateData({
            keys: ["comments", activeLng, id],
            value: comment,
            isNotUpdateLastUpdatedTime,
        });
    };

    const deleteComment = (id) => {
        updateData({
            keys: ["comments", activeLng, id],
            type: "delete",
            isNotUpdateLastUpdatedTime: true,
        });
    };

    const addComment = async () => {
        setIsLoadingAddCommentBtn(true);
        try {
            const index = Object.keys(data.comments[activeLng]).length;
            const newComment = await generateComment({
                lang: activeLng,
                index,
            });
            updateData({
                keys: ["comments", activeLng, Date.now()],
                value: { ...newComment },
            });
            scrollToBottom();
        } catch (err) {
            createErrorMessage(getResponseErrorsList(err));
        }
        setIsLoadingAddCommentBtn(false);
    };

    const content = data?.comments?.[activeLng];

    const { isLimitDone, countAutoResponse, isDisabledTenCommentsBtn } =
        useMemo(() => {
            let isLimitDone = false;
            let countAutoResponse = 0;
            let isDisabledTenCommentsBtn = false;

            if (!content) {
                return { isLimitDone, countAutoResponse };
            }
            if (Object.keys(content).length >= MAX_LIMIT_COMMENTS) {
                isLimitDone = true;
            }

            if (Object.keys(content).length >= 20) {
                isDisabledTenCommentsBtn = true;
            }
            Object.values(content).forEach((comment) => {
                if (comment.answerType === "auto") {
                    countAutoResponse += 1;
                }
            });

            return { isLimitDone, countAutoResponse, isDisabledTenCommentsBtn };
        }, [content]);

    useEffect(() => {
        if (
            activeLng &&
            data &&
            data.comments &&
            !data.comments[activeLng] &&
            Object.keys(data.comments).length
        ) {
            setActiveLng(Object.keys(data.comments)[0]);
        }
    }, [data, activeLng]);

    useEffect(() => {
        if (!errorData || typeof errorData === "string") return;

        setActiveLng(errorData.lang);

        setTimeout(() => {
            const blockElm = document.getElementById(
                errorData.commentId + errorData.fieldName
            );
            if (!blockElm) return;

            const inputElm =
                blockElm.querySelector("input") ||
                blockElm.querySelector("textarea");

            if (!inputElm) return;

            const errorBlock = blockElm.querySelector(".error-msg");
            if (errorBlock) {
                errorBlock.innerHTML = errorData.message;
            }

            blockElm.scrollIntoView({
                behavior: "smooth",
                block: "center", // Центрируем элемент на экране
            });

            blockElm.classList.add("error-state");

            const removeErrorState = () => {
                blockElm.classList.remove("error-state");
                inputElm?.removeEventListener("input", removeErrorState);
            };

            inputElm.addEventListener("input", removeErrorState);

            inputElm.focus();
        }, 200);
    }, [errorData]);

    return (
        <div className="comments-pwa" id="comments-pwa">
            <div style={{ width: "100%" }}>
                <LanguagesList
                    data={data?.comments}
                    addLanguage={addLanguage}
                    deleteLng={deleteLng}
                    activeLng={activeLng}
                    setActiveLng={setActiveLng}
                    isShowModal={isShowLanguagesModal}
                    setIsShowModal={setIsShowLanguageModal}
                    languagesList={languagesList}
                />
                <div>
                    {content && (
                        <div className="comments-list">
                            {Object.keys(content).map((key) => (
                                <Comment
                                    key={key}
                                    commentData={content[key]}
                                    id={key}
                                    update={(comment, isNotUpdateLastUpdatedTime) =>
                                        updateComment(key, comment, isNotUpdateLastUpdatedTime)
                                    }
                                    deleteComment={() => deleteComment(key)}
                                    createErrorMessage={createErrorMessage}
                                    activeLng={activeLng}
                                    indexAutoResponse={countAutoResponse}
                                    seed={data.seed}
                                />
                            ))}
                            <div className="buttons-block">
                                <Button
                                    size="small"
                                    title="Добавить комментарий"
                                    isLoading={isLoadingAddCommentBtn}
                                    isDisabled={isLimitDone}
                                    withoutRounded
                                    onClick={addComment}
                                />
                                <Button
                                    size="small"
                                    title="Добавить 10 комментариев"
                                    isDisabled={isDisabledTenCommentsBtn}
                                    withoutRounded
                                    isLoading={isLoadingAddTenCommentsBtn}
                                    onClick={addTenComments}
                                />
                            </div>
                        </div>
                    )}
                </div>
            </div>
            {content && <PreviewPWA data={data} activeLang={activeLng} scrollToElm="comments"/>}
        </div>
    );
};

export default Comments;
